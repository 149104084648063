#Footer {
  background-color: #04171b !important;
  font-family: sans-serif;
  width: 100%;
  z-index: 999;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  background: #111;
  padding: 30px 0;
  color: #fff;

  a {
    color: #03c4eb !important;
    transition: 0.5s;
    text-decoration: none;
    font-size: 13px;
  }

  a:hover,
  a:active,
  a:focus {
    color: #03c5ec !important;
    outline: none;
    text-decoration: none;
  }
}
