#reviews {
  padding: 20px 0 20px 0;

  @media only screen and (max-width: 992px) {
    padding: 10px;
  }
  @media only screen and (max-width: 775px) {
    padding: 5px;
  }
  @media only screen and (max-width: 575px) {
    max-width: 250px;
    margin-left: 60px;
    margin-top: 15px;
  }
}
