.modal-header {
  background-color: #04171b;
  border: black;
}
.modal-title {
  color: #c2f4fe;
  font-weight: 600;
  text-align: center;
  font-size: 26px;
  text-decoration: none;
}

.modal-footer {
  background-color: rgb(240, 236, 236);
}

.modal-body {
  text-align: center;
}

.modal-body a {
  color: white;
  font-size: 20px;
  text-align: center;
  font-weight: 400;
  padding: 10px;
  background-color: #3f3bb0;
  border-radius: 25px;
  #whatsapp {
    color: white;
  }
}
a:hover {
  color: #c2f4fe;
  font-weight: 400;
  font-size: 22px;
  text-decoration: none;
  background-color: #04171b;
  #whatsapp {
    color: #c2f4fe;
  }
}

a:link {
  text-decoration: none;
}
a:active {
  text-decoration: none;
  color: white;
}

.btn.btn-primary {
  border-radius: 10px;
  border-color: #435fb9;
  background: white;
  transition: all 0.2s linear;
  font-size: 15px;
  text-align: center;
  color: #435fb9;
  &:hover {
    font-weight: 500;
    font-size: 17px;
  }
}
