#Quote {
  margin-bottom: 50px;

  p,
  i {
    font-weight: 550;
    font-size: 25px;
    line-height: 160%;
    text-align: center;
    @media only screen and (max-width: 992px) {
      font-size: 25px;
    }
    @media only screen and (max-width: 775px) {
      font-size: 22px;
    }
    @media only screen and (max-width: 575px) {
      font-size: 20px;
    }
  }
}
