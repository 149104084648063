#SocialBar {
  .icon-bar {
    position: fixed;
    top: 55%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 999;
  }

  /* Style the icon bar links */
  .icon-bar a {
    display: block;
    text-align: center;
    padding: 16px;
    transition: all 0.3s ease;
    color: white;
    font-size: 14px;
    width: 45px;
    height: 30px;
    padding-bottom: 30px;
  }

  /* Style the social media icons with color, if you want */

  .facebook {
    background: #04171b;
    color: white !important;
  }

  .facebook:hover {
    color: #c2f4fe !important;
    transform: scale(1.1, 1);
  }

  .twitter {
    background: #04171b;
    color: white !important;
  }

  .twitter:hover {
    color: #c2f4fe !important;
    transform: scale(1.1, 1);
  }

  .twitter:hover {
    color: #c2f4fe !important;
    transform: scale(1.1, 1);
  }

  .linkedin {
    background: #04171b;
    color: white !important;
  }

  .linkedin:hover {
    color: #c2f4fe !important;
    transform: scale(1.1, 1);
  }

  .instagram {
    background: #04171b;
    color: white !important;
  }

  .instagram:hover {
    color: #c2f4fe !important;
    transform: scale(1.1, 1);
  }

  .youtube {
    background: #04171b;
    color: white !important;
  }

  .youtube:hover {
    color: #c2f4fe !important;
    transform: scale(1.1, 1);
  }
}
